@font-face {
  font-family: "poppins";
  font-weight: 300;
  src: url("../font/poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: "poppins";
  font-weight: 400;
  src: url("../font/poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "poppins";
  font-weight: 500;
  src: url("../font/poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "poppins";
  font-weight: 600;
  src: url("../font/poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "poppins";
  font-weight: 700;
  src: url("../font/poppins/Poppins-Bold.ttf");
}
body {
  margin: 0;
  padding: 0;
  font-family: poppins;
  background-color: rgba(50, 50, 0, 0.02);
  color: #222722;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: poppins;
  margin: 0px;
}
h1 {
  font-size: 72px;
  font-weight: 400;
}
h2 {
  font-size: 65px;
  font-weight: 400;
}
h3 {
  font-size: 48px;
  font-weight: 600;
}
h4 {
  font-size: 36px;
  font-weight: 700;
}
h5 {
  font-size: 30px;
  font-weight: 600;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}
.turn_coin_info {
  width: 420px;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.5);
  float: right;
  padding: 30px 25px;
}
.turn_coin_info .coin_name {
  font-size: 28px;
  color: #ffffff;
}
.turn_coin_info .coin_name .turn {
  color: #D0AD6A;
}
.turn_coin_info .coin_name sup {
  font-size: 16px;
}
.turn_coin_info .coin_detail p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  .turn_coin_info {
    float: none;
    margin: 0 auto;
    padding: 30px 0px;
  }
  .turn_coin_info .coin_name {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .turn_coin_info .coin_name sup {
    font-size: 12px;
  }
  .turn_coin_info .coin_detail p {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
@media all and (max-width: 468px) {
  .turn_coin_info {
    width: 100vw;
    float: none;
    margin: 0 auto;
  }
}
.turn_coin_chart {
  width: 420px;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 32px;
}
.turn_coin_chart .xaxis_value {
  padding: 0;
  text-align: center;
}
.turn_coin_chart .xaxis_value button {
  width: 45px;
  height: 20px;
  background: none;
  border: none;
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  outline: 0;
}
.turn_coin_chart .xaxis_value button:hover {
  cursor: pointer;
}
.turn_coin_chart .xaxis_value .active {
  width: 45px;
  height: 20px;
  background-image: linear-gradient(to bottom, #D9BA7E, #FFFFFF);
  border: none;
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #000000;
}
.turn_coin_chart .recharts-wrapper {
  margin: 0 auto;
  left: -30px;
}
@media all and (max-width: 1200px) {
  .turn_coin_chart {
    padding: 10px 0px;
    margin: 0 auto;
  }
  .turn_coin_chart .xaxis_value {
    padding: 0;
  }
}
@media all and (max-width: 468px) {
  .turn_coin_chart {
    width: 100vw;
    margin: 0 auto;
  }
}
.custom-tooltip {
  background-color: white;
  color: black;
  font-size: 14px;
  padding: 10px;
}
.custom-tooltip .labindex {
  text-align: left;
  font-size: 12px;
}
.desc_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 275px;
  background-color: #ffffff;
  border-right: 2px solid #D0AD6A;
  padding: 0px 27px 0px 27px;
}
.desc_item img {
  width: 60px;
  height: 60px;
  -webkit-filter: opacity(0.5) drop-shadow(0 0 0 #D0AD6A);
  filter: opacity(0.5) drop-shadow(0 0 0 #D0AD6A);
}
.desc_item .title {
  margin-top: 10px;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
}
.desc_item .description {
  line-height: 1.29;
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  width: 300px;
}
.desc_item.revolution_card {
  border: none;
}
@media all and (max-width: 1440px) {
  .desc_item {
    padding: 0 32px;
  }
}
@media all and (max-width: 1280px) {
  .desc_item {
    padding: 0 16px;
  }
}
@media all and (max-width: 1200px) {
  .coin_description_block .card_description .right_side_desc .card_description-under-title {
    width: 100%;
  }
}
@media all and (max-width: 996px) {
  .desc_item {
    padding: 0 0;
    border: none;
  }
}
.turncoin-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 336px;
  height: 410px;
  margin-top: 63px;
  margin-right: 48px;
}
.turncoin-logo .turncoin-logo-image {
  width: 315px;
  margin-bottom: 25px;
}
.turncoin-logo .turncoin-logo-title {
  display: block;
  font-size: 70px;
}
@media all and (max-width: 576px) {
  .turncoin-logo .turncoin-logo-title {
    font-size: 50px;
  }
}
.turncoin-logo .turncoin-logo-title .turn {
  color: #D0AD6A;
  font-weight: 700;
}
.turncoin-logo .turncoin-logo-title .coin {
  font-weight: 500;
}
.user-details-block-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.user-details-block-wrapper .order-table-container .order-table {
  max-width: inherit;
  width: 100%;
}
.ant-pagination .ant-table-pagination {
  display: block;
}
.ant-table-bordered .ant-table-tbody > tr > td {
  padding: 0;
}
.ant-descriptions-title {
  margin-bottom: 20px;
  color: #000;
  font-weight: 700;
  font-size: 22px;
  text-transform: capitalize;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  font-size: 20px;
  font-weight: 700;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  font-size: 20px;
  margin-left: 10px;
  color: #D0AD6A;
}
.user-details-content {
  margin-bottom: 40px;
}
.backend .mb-order-table.table-responsive {
  width: 100%;
}
.backend .order {
  display: flex;
  flex-direction: column;
  border-top: 5px solid #ccc;
  width: 100%;
}
.backend .order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  border-bottom: 1px solid #ccc;
  font-size: 22px;
  transition: 0.3s linear;
}
.backend .order-item:hover {
  background: #e4e4e4;
  cursor: pointer;
}
@media all and (max-width: 470px) {
  .backend .order-item {
    font-size: 18px;
  }
}
@media all and (max-width: 390px) {
  .backend .order-item {
    font-size: 14px;
  }
}
@media all and (max-width: 350px) {
  .backend .order-item .value {
    font-size: 12px;
  }
}
.news-editor {
  min-height: 100%;
  width: calc(100vw - 88px);
}
@media (min-width: 991px) {
  .news-editor {
    width: calc(100vw - 288px);
  }
}
@media (min-width: 1280px) {
  .news-editor {
    width: calc(100vw - 390px);
  }
}
.news-editor .title {
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
}
.news-editor .content {
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 300px;
}
.file-uploader {
  margin-top: 12px;
  margin-bottom: 12px;
}
.file-uploader .preview-wrapper {
  width: 100%;
  padding-top: 25%;
  position: relative;
}
.file-uploader .preview-wrapper .image-preview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file-uploader .preview-wrapper .close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 40px;
  color: white;
}
.xchange-list-container {
  font-size: 30px;
  line-height: 72px;
}
.xchange-list-container .xchange-list-title h3 {
  font-size: 48px;
  font-weight: 600;
}
.xchange-list-container .xchange-list-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.xchange-list-container .xchange-list-content .xchange-list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.xchange-list-container .xchange-list-content .xchange-list-item > :first-child {
  font-weight: 400;
}
.xchange-list-container .xchange-list-content .xchange-list-item > :last-child {
  text-align: right;
  font-weight: 600;
}
@media all and (max-width: 991px) {
  .xchange-list-container {
    font-size: 20px;
    line-height: 48px;
  }
  .xchange-list-container .xchange-list-title h3 {
    font-size: 20px;
    font-weight: 700;
  }
  .xchange-list-container .xchange-list-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .xchange-list-container .xchange-list-content .xchange-list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .xchange-list-container .xchange-list-content .xchange-list-item > :first-child {
    font-weight: 400;
  }
  .xchange-list-container .xchange-list-content .xchange-list-item > :last-child {
    text-align: right;
    font-weight: 600;
  }
}
@media all and (max-width: 480px) {
  .xchange-list-container {
    font-size: 18px;
    line-height: 24px;
  }
  .xchange-list-container .xchange-list-title h3 {
    font-size: 24px;
    text-align: center;
  }
  .xchange-list-container .xchange-list-content .xchange-list-item {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xchange-list-container .xchange-list-content .xchange-list-item > :first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xchange-list-container .xchange-list-content .xchange-list-item > :last-child {
    font-weight: 700;
    text-align: center;
  }
}
@-webkit-keyframes autofill {
  to {
    background-color: transparent !important;
  }
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.securitize-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 298px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border: 1px solid #4787ac;
  border-radius: 5px;
  color: #4787ac;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}
.securitize-btn-img {
  width: 30px;
}
.login-text {
  margin-bottom: 0;
  padding-left: 9px;
}
.login_body_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 792px;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  padding: 130px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  text-align: center;
}
.login_body {
  font-family: Poppins, sans-serif;
  height: 100%;
  width: 100%;
  color: #fff;
}
.turncoin_2col_input {
  padding: 10px;
}
.turncoin_input_field {
  margin-bottom: 0px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #d0ad6a;
  border-radius: 0px;
  width: 50%;
  color: black;
}
.turncoin_login_btn {
  padding-top: 10px;
}
.login_form_title {
  color: #fff;
}
.turncoin_submit_action {
  width: 50%;
  height: 100%;
  color: black;
}
.login_page_logo {
  height: 196.36px;
  margin-bottom: 95px;
}
.login_page_paragraph {
  max-width: 521px;
  margin-right: auto;
  margin-bottom: 49px;
  margin-left: auto;
  line-height: 24px;
  font-weight: 200;
  text-align: center;
}
.xchange_copywrite_message {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
  color: #f6f8f6;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  bottom: 0px;
  position: relative;
  text-align: center;
  width: 100%;
  justify-content: center;
}
body {
  background-color: #101010;
}
.consultant_success_title {
  color: #fff;
}
.consultant_success_description {
  color: #fff;
}
.register_body {
  font-family: Poppins, sans-serif;
  position: absolute;
  height: 100%;
  width: 100%;
  color: #fff;
}
.poppins-font {
  font-family: "Poppins", sans-serif;
}
.xchange_copywrite_message {
  justify-content: center;
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
  left: 0;
}
.register_body_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  width: 100%;
  max-width: 792px;
  margin-right: auto;
  margin-left: auto;
  padding: 130px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  text-align: center;
  position: absolute;
}
.register_page_logo {
  width: 200px;
  margin-bottom: 30px;
}
.coin_logo {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
}
.consultant_success_title {
  font-size: 36px;
  margin-bottom: 20px;
}
.consultant_success_title span {
  color: #d8b97e;
}
.consultant_success_description {
  font-size: 18px;
  margin-bottom: 20px;
}
.login_form_btn_wrapper {
  text-align: center;
}
.turncoin_register-page_btn {
  background-color: #d8b97e;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  width: 100%;
}
.turncoin_register-page_btn:hover {
  background-color: #d8b97e;
  cursor: pointer;
}
.turncoin_register-page_btn input.turncoin_submit_action {
  display: none;
}
.login_body_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.login_page_logo {
  width: 150px;
  height: 150px;
  margin-bottom: 50px;
}
.login_form_block {
  width: 100%;
}
.login_form_title {
  font-size: 36px;
  color: white;
  margin-bottom: 50px;
}
input[type="password"],
input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.turncoin_registration_input_field {
  background-color: #f2f2f2;
  color: black;
}
.turncoin_login_btn {
  color: #fff;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.turcoin_checkbox_label {
  color: white;
}
.turncoin_terms_checkbox {
  border: 2px solid white;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.check-active {
  background-color: #d8b97e;
}
.login_page_paragraph {
  font-size: 14px;
  color: white;
  margin-top: 20px;
}
.login_page_paragraph a {
  color: white;
  text-decoration: underline;
}
.xchange_copywrite_message {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 50px;
}
.error_message {
  padding-top: 10px;
  color: red;
  background-color: #000;
}
@media only screen and (max-width: 480px) {
  .securitize-btn {
    width: 100%;
  }
}
.auth-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url("../img/sport-bg.jpg");
  background-position: center;
  background-size: auto auto;
}
.auth-container .auth-footer {
  color: #FFF;
  margin-top: 56px;
  margin-bottom: 64px;
  text-align: center;
}
@media all and (max-width: 1024px) {
  .auth-container .auth-footer {
    width: 90%;
  }
}
.auth-container .auth-content {
  color: white;
  max-width: 1024px;
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 17px;
}
.auth-container .auth-content .logo-container {
  margin-top: 120px;
}
.auth-container .auth-content p {
  font-size: 20px;
  margin-top: 62px;
}
.auth-container .auth-content .login-form {
  margin-top: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth-container .auth-content .login-form .form-fields {
  padding: 24px 36px;
}
.auth-container .auth-content .login-form .form-fields .ant-form-item {
  margin-right: 24px;
}
.auth-container .auth-content .login-form .form-fields .ant-form-item .ant-form-item-control-wrapper {
  width: 100%;
}
.auth-container .auth-content .login-form .form-fields .ant-form-item:last-child {
  margin-right: 0;
}
.auth-container .auth-content .login-form .form-fields input {
  background-color: rgba(0, 0, 0, 0.65);
  width: 360px;
  height: 48px;
  font-size: 22px;
  border-color: #D0AD6A;
}
.auth-container .auth-content .login-form .form-fields input::placeholder {
  color: #838383;
}
@media all and (max-width: 910px) {
  .auth-container .auth-content .login-form .form-fields input {
    width: 100%;
  }
}
@media all and (max-width: 910px) {
  .auth-container .auth-content .login-form .form-fields .ant-form-item {
    margin-bottom: 15px;
  }
  .auth-container .auth-content .login-form .form-fields .ant-form-item-with-help {
    margin-bottom: 5px;
  }
}
@media all and (max-width: 768px) {
  .auth-container .auth-content .login-form .form-fields .ant-form-item {
    margin-right: 0px;
  }
}
.auth-container .auth-content .login-form .remember {
  margin-top: 25px;
  height: 22px;
  font-size: 18px;
}
.auth-container .auth-content .login-form .remember .ant-form-item {
  margin-right: 0;
}
.auth-container .auth-content .login-form .remember .ant-form-item .ant-checkbox-wrapper span {
  font-size: 18px;
  background-color: transparent;
  border-radius: 5px;
  width: 24px;
  height: 24px;
}
.auth-container .auth-content .login-form .submission {
  margin-top: 60px;
}
.auth-container .auth-content .login-form .submission .login-button,
.auth-container .auth-content .login-form .submission .register-button {
  width: 192px;
  height: 48px;
  cursor: pointer;
  border-radius: unset;
}
.auth-container .auth-content .login-form .submission .login-button {
  margin-right: 48px;
}
@media all and (max-width: 768px) {
  .auth-container .auth-content .login-form .submission .login-button,
  .auth-container .auth-content .login-form .submission .register-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
  }
}
.auth-container .auth-content .login-form .forgot-password {
  margin-top: 53px;
}
.auth-container .auth-content .login-form .forgot-password a {
  font-size: 18px;
  color: #D0AD6A;
}
.auth-container .auth-content .has-error .ant-form-explain {
  margin: 5px 0 15px;
  font-size: 20px;
}
.auth-container .auth-content input {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #D0AD6A;
  padding: 10px 15px;
  color: white;
  height: 45px;
  font-size: 17px;
}
.auth-container .auth-content input::placeholder {
  color: #DDD;
}
.auth-container .auth-content input[type="checkbox"] {
  color: white;
  margin-top: 4px;
  margin-right: 15px;
}
.auth-container .auth-content button {
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  font-size: 20px;
  cursor: pointer;
  border: 2px solid #D0AD6A;
  color: #000000;
  font-weight: 600;
}
.auth-container .auth-content button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.auth-container .auth-content label {
  color: white;
}
.auth-container .auth-content .errors {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border: solid 1px;
  color: red;
  font-size: 22px;
  width: 100%;
}
.auth-container .auth-content .errors ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media all and (max-width: 910px) {
  .auth-container .auth-content {
    width: 100%;
    padding: 50px;
  }
}
@media all and (max-width: 768px) {
  .auth-container .auth-content {
    padding: 20px;
  }
  .auth-container .auth-content > p {
    font-size: 17px;
  }
  .auth-container .auth-content .form-fields {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .auth-container .auth-content input + input,
  .auth-container .auth-content .register-button {
    margin: 0;
    margin-top: 5px;
  }
}
@media all and (max-width: 575px) {
  .auth-container .auth-content .logo-container img {
    width: 200px;
  }
}
.register {
  background-image: url("../img/sport-bg.jpg");
}
.register .register-footer {
  color: #FFF;
  margin-top: 71px;
  margin-bottom: 81px;
  text-align: center;
}
@media all and (max-width: 1024px) {
  .register .register-footer {
    width: 90%;
  }
}
.register .auth-content .logo-container {
  margin-top: 144px;
}
@media all and (max-width: 768px) {
  .register .auth-content {
    padding-top: 0px;
  }
}
.register .auth-content .auth-content {
  flex-grow: none;
}
.register .auth-content .form-fields,
.register .auth-content .submission,
.register .auth-content .submission > a.login-container {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.register .auth-content .form-fields {
  width: 432px;
  margin-top: 48px;
}
.register .auth-content .form-fields .form-group {
  margin: 24px 24px 0px 24px;
  background-color: rgba(0, 0, 0, 0.65);
}
.register .auth-content .form-fields input {
  height: 48px;
  font-size: 22px;
}
.register .auth-content .form-fields input::placeholder {
  color: #838383;
}
@media all and (max-width: 910px) {
  .register .auth-content .form-fields .errors {
    width: 100%;
  }
}
.register .auth-content .form-fields .agreement {
  padding: 0px 24px;
  margin-top: 31px;
  font-size: 18px;
}
.register .auth-content .form-fields .agreement .ant-form-item {
  margin-bottom: 0;
}
.register .auth-content .form-fields .agreement .ant-form-item .ant-checkbox-wrapper {
  text-align: left;
  margin-left: 55px;
  line-height: 1.5;
}
.register .auth-content .form-fields .agreement .ant-form-item .ant-checkbox-wrapper .ant-checkbox {
  margin-left: -48px;
}
.register .auth-content .form-fields .agreement .ant-form-item .ant-checkbox-wrapper span {
  font-size: 18px;
  line-height: 1.5;
  background-color: transparent;
  border-radius: 5px;
  width: 24px;
  height: 24px;
}
.register .auth-content .form-fields .agreement .ant-form-item .ant-checkbox-wrapper span:last-child {
  padding-left: 24px;
}
.register .auth-content .form-fields .agreement a {
  color: #D0AD6A;
  font-weight: 600;
}
.register .auth-content .form-fields .register-button {
  height: 48px;
  margin: 10px auto;
}
.register .auth-content .form-fields .submission {
  margin-top: 39px;
  padding: 0 24px;
}
@media all and (max-width: 480px) {
  .register .auth-content .form-fields {
    width: 100%;
  }
  .register .auth-content .form-fields .agreement,
  .register .auth-content .form-fields .submission {
    padding: 0 0;
  }
}
.register-kyc .auth-content .form-fields {
  width: 400px;
  padding: 30px 0;
}
.register-kyc .auth-content .form-fields select {
  width: 100%;
  height: 40px;
  background-color: transparent;
}
.register-kyc .auth-content .form-fields select option {
  color: black;
}
.register-kyc .auth-content .form-fields iframe {
  width: 100%;
  min-height: 300px;
  margin-top: 30px;
}
.register-kyc .auth-content .form-fields .success {
  color: lightgreen;
}
@media all and (max-width: 768px) {
  .register-kyc .auth-content {
    padding: 20px;
    padding-top: 80px;
  }
  .register-kyc .auth-content > p {
    font-size: 17px;
  }
  .register-kyc .auth-content .form-fields {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.register-kyc .auth-content .submission .disable_btn {
  background-color: white;
}
.register-kyc .auth-content .submission .active_btn {
  background-color: #D9BA7E;
}
.ant-checkbox-wrapper {
  text-align: left;
  margin-left: 23px;
  line-height: 1.5;
}
.ant-checkbox-wrapper .ant-checkbox {
  margin-left: -23px;
}
.ant-checkbox-wrapper span {
  font-size: 16px;
  line-height: 1.5;
}
.forgot .form-fields {
  margin-top: 50px;
  align-items: center;
}
.forgot .form-fields .ant-form-item,
.forgot .form-fields button,
.forgot .form-fields .errors {
  width: 360px;
  min-height: 48px;
  margin-bottom: 50px;
}
@media all and (max-width: 480px) {
  .forgot .form-fields {
    width: 100%;
  }
  .forgot .form-fields .ant-form-item,
  .forgot .form-fields button,
  .forgot .form-fields .errors {
    width: 100%;
  }
}
.kyc-status {
  margin-top: 80px;
  margin-bottom: 150px;
}
@media all and (max-width: 768px) {
  .kyc-status {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
.kyc .left-side .turncoin {
  margin-left: -70px;
}
.kyc .right-side {
  margin-top: 70px;
}
.kyc .right-side > span {
  margin-top: 20px;
}
.kyc .right-side span {
  display: inline-block;
}
.kyc .right-side .warning {
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
}
.kyc .right-side .warning .warning-img {
  margin-top: 5px;
}
.kyc .right-side .warning .warning-para {
  margin-left: 20px;
}
.kyc .right-side .warning .warning-para .warning-content {
  margin-top: 45px;
  color: red;
}
.kyc .right-side > .kyc-button-group {
  margin-top: 80px;
}
.kyc h2 {
  font-size: 60px;
  font-weight: 700;
}
.kyc span {
  font-size: 25px;
}
.kyc .kyc-button-group {
  display: flex;
}
.kyc .kyc-button-group .kyc-button {
  margin-right: 100px;
}
.kyc .kyc-button-group .kyc-button:first-child {
  background-color: #D9BA7E;
}
.kyc .kyc-button-group .kyc-button:last-child {
  margin-right: 0px;
  background-color: #F3EAD8;
}
.kyc .kyc-button {
  width: 290px;
  height: 75px;
  border: none;
  font-size: 25px;
  font-weight: 600;
}
@media all and (max-width: 1199px) {
  .kyc .left-side {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .kyc .left-side .turncoin {
    margin-left: 0px;
    width: 50%;
    height: 50%;
  }
  .kyc .left-side .turncoin-name {
    width: 80%;
    height: 80%;
  }
  .kyc .right-side .warning .warning-img {
    width: 30px;
    height: 30px;
  }
  .kyc h2 {
    text-align: center;
  }
  .kyc span {
    line-height: 34px;
  }
  .kyc .kyc-button-group {
    justify-content: center;
  }
  .kyc .kyc-button-group .kyc-button {
    margin-bottom: 30px;
    width: 280px;
  }
}
@media all and (max-width: 768px) {
  .kyc h2 {
    text-align: center;
    font-size: 24px;
  }
  .kyc span {
    font-size: 16px;
    line-height: 34px;
  }
  .kyc .kyc-button-group {
    justify-content: center;
    flex-wrap: wrap;
  }
  .kyc .kyc-button-group .kyc-button {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .kyc .kyc-button-group .kyc-button:last-child {
    margin-bottom: 0px;
  }
  .kyc .right-side > .kyc-button-group {
    margin-top: 30px;
  }
}
.header {
  position: relative;
  width: 100%;
  height: 120px;
  padding: 20px 325px;
  z-index: 999;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.header .logo {
  color: #d0ad6a;
  font-size: 55px;
  font-weight: 700;
  display: flex;
}
.header .logo p {
  color: #000;
  font-weight: 400;
}
.header .logo p span {
  font-size: 40px;
}
.header .turn_coin_logo {
  cursor: pointer;
}
.header .nav_items {
  width: 100%;
  text-align: right;
  padding: 15px;
}
.header .nav_items a {
  cursor: pointer;
  font-size: 18px;
  color: #000000;
  margin-right: 20px;
  border: none;
  padding: 5px 0px;
}
.header .nav_items .selected {
  border-bottom: 2px solid #D0AD6A;
  font-weight: 600;
}
.header .nav_items button {
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
}
.header .nav_items button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.header .fixed_nav {
  text-align: right;
  padding: 15px;
}
.header .fixed_nav a {
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;
  margin-left: 20px;
}
.header .mobile_menu {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 120px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  height: 0px;
}
.header .mobile_menu_wrapper {
  padding: 30px 10px;
}
.header .mobile_menu.show {
  visibility: visible;
  opacity: 1;
  height: 350px;
}
.header .mobile_menu svg {
  position: absolute;
  font-size: 24px;
  color: #ffffff;
  right: 10px;
  top: 10px;
}
.header .mobile_menu ul {
  padding: 0;
  list-style: none;
}
.header .mobile_menu ul li {
  cursor: pointer;
  padding: 20px 0px;
}
.header .mobile_menu ul li a {
  font-size: 18px;
  color: #ffffff;
  padding: 5px 0px;
}
.header .mobile_menu .selected {
  border-bottom: 2px solid #D0AD6A;
}
@media all and (max-width: 1850px) {
  .header {
    padding: 33px 290px;
  }
}
@media all and (max-width: 1660px) {
  .header {
    padding: 33px 200px;
  }
}
@media all and (max-width: 1600px) {
  .header {
    padding: 33px 150px;
    height: 80px;
  }
  .header .logo {
    font-size: 45px;
  }
  .header .logo p span {
    font-size: 35px;
  }
  .header .nav_items a {
    font-size: 16px;
  }
  .header .nav_items button {
    width: 175px;
    height: 40px;
    font-size: 16px;
  }
}
@media (max-width: 1440px) {
  .header {
    padding: 33px 115px;
  }
}
@media (max-width: 1300px) {
  .header {
    padding: 33px 80px;
  }
}
@media (max-width: 1200px) {
  .header {
    padding: 33px 30px;
  }
}
@media all and (max-width: 1000px) {
  .header {
    padding: 33px 0px;
  }
  .header .turn_coin_logo_wrapper {
    margin: 0 auto;
    text-align: center;
  }
  .header .turn_coin_logo_wrapper .turn_coin_logo {
    width: 100%;
    max-width: 304px;
  }
  .header .nav_items {
    display: none;
  }
  .header .fixed_nav {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  .header .turn_coin_logo_wrapper {
    margin: 0;
    width: 80%;
  }
  .header .logo {
    font-size: 45px;
  }
  .header .logo p span {
    font-size: 30px;
  }
}
.footer {
  width: 100%;
  background-color: #000000;
  padding: 95.7px 91.2px;
  border-top: 2px solid #D0AD6A;
}
.footer .footer-title {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Rajdhani';
  font-weight: 400;
  font-size: 65px;
}
.footer .footer-title span {
  color: #D9BA7E;
  font-size: 42px;
}
.footer .footer-logo {
  margin: 48px auto;
  margin-top: 0;
  display: block;
  height: 192px;
}
.footer .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer .content .social_icon {
  margin-bottom: 48px;
  text-align: center;
}
.footer .content .social_icon .right_margin {
  margin-right: 10.2px;
}
.footer .content .company {
  text-align: center;
  margin-bottom: 32.6px;
}
.footer .content .company > img {
  width: 192px;
  height: 192px;
}
.footer .content .links > span {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footer .content .links > span a {
  color: #ffffff;
  margin: 0 10px;
}
.footer .content .contacts {
  margin-bottom: 53.8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
}
.footer .content .contacts .contact_info {
  margin-bottom: 0px;
  padding: 0;
  text-align: center;
  line-height: 24px;
  height: 69px;
  list-style: none;
}
.footer .content .contacts .contact_info li {
  color: #ffffff;
  line-height: 24px;
}
.footer .content .contacts .contact_info .title {
  font-weight: 600;
}
.footer .content .contacts .contact_info .country {
  font-weight: 600;
  color: #ffffff;
}
.footer .content .contacts .contact_info .email {
  margin-right: 20px;
  color: #ffffff;
}
.footer .content .contacts .contact_info .email:hover {
  text-decoration: underline;
}
.footer .content .contacts .contact_info:last-child {
  margin-right: 0px;
}
.footer .content .copy_right p {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  line-height: 18px;
}
@media all and (max-width: 640px) {
  .footer {
    padding: 35px 0 35px 0;
  }
  .footer .content .social_icon {
    margin-bottom: 24px;
  }
  .footer .content .footer-logo {
    margin-bottom: 24px;
  }
  .footer .content .contacts {
    font-size: 14px;
    margin-bottom: 18px;
    flex-direction: column;
  }
  .footer .content .contacts .contact_info {
    height: 66px;
  }
  .footer .content .contacts::after {
    content: ' ';
    width: 48px;
    height: 14px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #ffffff;
  }
  .footer .content .copy_right p {
    color: #ffffff;
    font-size: 12px;
    line-height: 15px;
  }
}
.dashboard-container > .dashboard .dashboard-menu,
.dashboard-container > .dashboard .dashboard-content {
  box-sizing: border-box;
}
.dashboard-container > .dashboard .dashboard-menu {
  background-color: #1C1C1C;
}
.dashboard-container > .dashboard .dashboard-menu ul {
  background-color: #1C1C1C;
  padding-top: 10px;
}
@media all and (max-width: 994px) {
  .dashboard-container > .dashboard .dashboard-menu ul {
    width: 48px;
  }
}
.dashboard-container > .dashboard .dashboard-menu ul.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 0px !important;
}
.dashboard-container > .dashboard .dashboard-menu ul.ant-menu-inline-collapsed > .ant-menu-item > .menu-item {
  padding-left: 5px;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item {
  display: flex;
  align-items: center;
  border-width: 0 0 0 5px;
  border-style: solid;
  border-color: #1C1C1C;
  padding-left: 20px;
  height: 48px;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item .anticon {
  width: 30px;
  margin-right: 8px;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item svg {
  fill: #D0AD6A !important;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item:hover {
  background-color: #D0AD6A;
  border-color: #fff;
  color: #FFFFFF;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item:hover svg {
  fill: #FFFFFF !important;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item-selected {
  border-color: #fff;
  background-color: #D0AD6A;
  color: #FFFFFF;
  font-weight: 700;
}
.dashboard-container > .dashboard .dashboard-menu ul .menu-item-selected svg {
  fill: #FFFFFF !important;
}
.dashboard-container > .dashboard .dashboard-menu ul .ant-menu-item-selected {
  background-color: #D0AD6A;
}
.dashboard-container > .dashboard .dashboard-menu ul li {
  background-color: #1C1C1C;
  margin: 0px 0px 0px 0px;
  height: 56px;
  padding: 0px 0px 0px 0px;
  justify-content: center;
}
@media all and (min-width: 994px) {
  .dashboard-container > .dashboard .dashboard-menu ul li {
    display: flex;
  }
}
.dashboard-container > .dashboard .dashboard-menu .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 12px !important;
}
.dashboard-container > .dashboard .dashboard-menu a {
  color: #fff;
  font-size: 20px;
}
.dashboard-container > .dashboard .dashboard-menu a + a {
  margin-top: 5px;
}
.dashboard-container > .dashboard .dashboard-content {
  background-color: white;
  flex-grow: 1;
  padding: 35px 75px;
  justify-content: flex-start;
}
@media all and (max-width: 994px) {
  .dashboard-container > .dashboard .dashboard-content {
    padding: 20px 18px;
  }
}
.dashboard-header.header .logo {
  color: #d0ad6a;
  font-size: 40px;
  font-weight: 700;
  display: flex;
  font-family: poppins;
}
.dashboard-header.header .logo p,
.dashboard-header.header .logo span {
  font-family: poppins;
}
.dashboard-header.header .logo p {
  color: #fff;
  font-weight: 400;
}
.dashboard-header.header .logo p span {
  font-size: 25px;
}
.dashboard-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
.dashboard-container .dashboard {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 160px);
}
.dashboard-container .dashboard .dashboard-menu {
  min-height: unset;
}
.dashboard-header {
  padding: 25px 34px;
  min-height: 120px;
  height: unset;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #000;
  color: #FFFFFF;
}
.dashboard-header .turn_coin_logo {
  height: 65px;
  width: auto;
}
.dashboard-header .mb-centered_logo {
  width: 50px;
}
.dashboard-header .centered,
.dashboard-header .mb-centered,
.dashboard-header .right-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-header .right-aligned a {
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  white-space: nowrap;
  border: none;
  padding: 5px 0px;
}
.dashboard-header .right-aligned .user_settings {
  margin-right: 20px;
}
.dashboard-header .right-aligned .selected {
  border-bottom: 2px solid #D0AD6A;
  font-weight: 600;
}
.dashboard-header .centered {
  flex-grow: 1;
}
.dashboard-header .centered .drivecoin {
  max-height: 65px;
  margin-right: 10px;
}
.dashboard-header .centered span {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
}
.dashboard-header .centered span .subcolor {
  color: #D9BA7E;
  font-weight: bold;
}
.dashboard-header .right-aligned .user_account {
  margin-left: 20px;
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  padding-right: 20px;
  text-transform: capitalize;
}
.dashboard-header .mb-centered {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
}
.dashboard-header .mb-centered .subcolor {
  color: #D9BA7E;
  font-weight: bold;
}
@media all and (max-width: 1320px) {
  .dashboard-header.header .centered span {
    font-size: 30px;
  }
}
@media all and (max-width: 1150px) {
  .dashboard-header.header .user_account,
  .dashboard-header.header .user_settings {
    display: none;
  }
  .dashboard-header.header .centered .drivecoin {
    max-height: 45px;
    margin-right: 10px;
  }
  .dashboard-header.header .centered span {
    font-size: 28px;
  }
}
@media all and (max-width: 992px) {
  .dashboard-header.header .logo p span {
    font-size: 30px;
  }
  .dashboard-header.header .centered .drivecoin {
    max-height: 32px;
    margin-right: 10px;
  }
  .dashboard-header.header .centered span {
    font-size: 24px;
  }
}
@media all and (max-width: 860px) {
  .dashboard-header.header .logo {
    font-size: 35px;
    align-items: center;
  }
  .dashboard-header.header .centered .drivecoin {
    max-height: 28px;
    margin-right: 10px;
  }
  .dashboard-header.header .centered span {
    font-size: 20px;
  }
}
@media all and (max-width: 767px) {
  .dashboard-header.header {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard-header.header .menu_bar {
    position: absolute;
    right: 30px;
    top: 34px;
  }
}
@media all and (max-width: 545px) {
  .dashboard-header.header .centered .drivecoin {
    display: none;
  }
}
@media all and (max-width: 410px) {
  .dashboard-header.header .centered {
    width: 160px;
  }
}
.notification-user-name {
  font-weight: 700;
  text-transform: capitalize;
}
.overview {
  overflow: hidden;
}
.overview a {
  color: #D0AD6A;
  text-decoration: underline;
}
.overview .notification {
  margin-bottom: 75px;
}
@media all and (max-width: 994px) {
  .overview .notification {
    margin-bottom: 15px;
  }
}
.overview .notification p {
  font-size: 18px;
}
@media all and (max-width: 994px) {
  .overview .notification p {
    line-height: 37px;
  }
}
.overview .overview-chart,
.overview .overview-table {
  min-height: 400px;
}
.overview .overview-chart .ant-table-title,
.overview .overview-table .ant-table-title {
  padding: 0;
}
.overview .overview-chart h1,
.overview .overview-table h1 {
  padding: 8px 15px 8px 15px;
  background-color: #D9BA7E;
  font-size: 18px;
  color: black;
  font-weight: bold;
}
.even {
  background: #EBEBEB;
}
.odd {
  background: #FFFFFF;
}
.order-table-container .order-table {
  max-width: 1000px;
  width: 100%;
}
.order-table-container .order-table .ant-table-thead > tr > th,
.order-table-container .order-table .ant-table-tbody > tr > td {
  padding: 0;
}
.order-table-container .mb-order-table {
  width: 100%;
}
.order-table-container .mb-order-table .order {
  margin-bottom: 24px;
  border-bottom: 1px solid #e2e2e2;
}
.order-table-container .mb-order-table .order .order-item {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  align-items: center;
  height: 48px;
}
.order-table-container .mb-order-table .order .order-item span {
  font-size: 20px;
}
.order-table-container .mb-order-table .order .order-item .label {
  font-size: 20px;
  font-weight: bold;
  color: '#07174A';
  font-weight: 'bold';
  text-align: 'center';
  display: 'inline-block';
  margin-bottom: 20;
}
.order-table-container .mb-order-table .order .order-item .value {
  font-size: 20px;
  color: '#000000';
}
.order-pagination {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.order-pagination .ant-pagination-item:hover,
.order-pagination .ant-pagination-item-active {
  font-weight: 500;
  background: #b7b7b7;
  border-color: #b7b7b7;
  border-radius: 20px;
}
.order-pagination .ant-pagination-item {
  border: none;
}
@media (max-width: 768px) {
  .order-pagination {
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .order-pagination .ant-pagination li {
    margin-right: 0;
  }
}
@media (max-width: 530px) {
  .order-table-container .mb-order-table .order .order-item span {
    font-size: 18px;
  }
  .order-table-container .mb-order-table .order .order-item .label {
    font-size: 18px;
  }
  .order-table-container .mb-order-table .order .order-item .value {
    font-size: 18px;
  }
}
@media (max-width: 430px) {
  .order-table-container .mb-order-table .order .order-item .value {
    font-size: 15px;
  }
  .order-table-container .mb-order-table .order .order-item span {
    font-size: 15px;
  }
}
@media (max-width: 370px) {
  .order-table-container .mb-order-table .order .order-item .label {
    font-size: 15px;
  }
  .order-table-container .mb-order-table .order .order-item .value {
    font-size: 12px;
  }
  .order-table-container .mb-order-table .order .order-item span {
    font-size: 12px;
  }
}
.change-password-container h3 {
  font-size: 32px;
}
.change-password-container .change-password-card {
  -webkit-box-shadow: 1px 1px 3px 3px #dbdbdb;
  box-shadow: 1px 1px 3px 3px #dbdbdb;
  padding: 10px;
  margin-top: 24px;
}
.change-password-container .change-password-card .form-field-name {
  font-size: 22px;
}
.change-password-container .change-password-card .ant-form-item .ant-form-item-control .ant-input {
  border-style: solid;
  border-width: 1px;
  border-color: #F3EAD8;
  border-radius: 1px;
  height: 40px;
  font-size: 20px;
  color: #000;
}
.change-password-container .change-password-card .change-password-btn {
  width: 230px;
  height: 55px;
  font-size: 24px;
  background-color: #D0AD6A !important;
  border: none;
  border-radius: 0px;
  color: #000000;
  font-weight: 600;
  margin: auto;
  display: block;
}
.change-password-container .change-password-card .change-password-btn.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.main_block .turncoin_holder {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media all and (max-width: 768px) {
  .main_block .turncoin_holder {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
.top-brand-block {
  width: 100%;
  color: #ffffff;
  background: radial-gradient(center, #212121 0%, #000 90%, #000 100%);
  position: relative;
}
.top-brand-block .top-brand-block-mask {
  background-image: url('../img/golden.jpg');
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
}
.top-brand-block .top-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-brand-block .coin_prices {
  position: absolute;
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #ffffff;
  font-size: 18px;
}
.top-brand-block .coin_prices span {
  display: inline-block;
}
.top-brand-block .coin_prices span + span {
  margin-left: 50px;
}
@media all and (max-width: 1600px) {
  .top-brand-block .coin_prices span + span {
    margin-left: 40px;
  }
}
@media all and (max-width: 1500px) {
  .top-brand-block .coin_prices span + span {
    margin-left: 30px;
  }
}
@media all and (max-width: 1400px) {
  .top-brand-block .coin_prices span + span {
    margin-left: 20px;
  }
}
@media all and (max-width: 1300px) {
  .top-brand-block .coin_prices span {
    font-size: 16px;
  }
  .top-brand-block .coin_prices span + span {
    margin-left: 20px;
  }
}
.top-brand-block .coin_prices.mobile {
  display: none;
}
@media all and (max-width: 1150px) {
  .top-brand-block .coin_prices {
    display: none;
  }
  .top-brand-block .coin_prices.mobile {
    display: block;
  }
  .top-brand-block .coin_prices.mobile .ant-carousel .slick-slide {
    text-align: center;
    height: 50px;
    margin-top: 0px;
    line-height: 1em;
    font-size: 16px;
    color: white;
    overflow: hidden;
  }
}
@media all and (max-width: 768px) {
  .top-brand-block .coin_prices.mobile .ant-carousel .slick-slide {
    margin-top: 20px;
  }
}
.top-brand-block .top-brand-logo {
  margin-top: 125px;
  width: 243px;
}
.top-brand-block .people-logo {
  width: 1200px;
  max-width: 100%;
}
.top-brand-block .slogan {
  margin-top: 33px;
  font-size: 65px;
  text-align: center;
}
.top-brand-block .slogan .medium {
  font-size: 48px;
  color: #CA9C55;
}
.top-brand-block .coin_price {
  margin-top: 24px;
  text-align: center;
}
.top-brand-block .coin_price .coin {
  color: #ffffff;
  font-size: 36px;
}
.top-brand-block .coin_price .coin .turn {
  margin-right: 20px;
}
.top-brand-block .coin_price .coin .turn span {
  color: #D0AD6A;
  font-weight: 700;
}
.top-brand-block .coin_price .coin .turn sup {
  font-size: 20px;
}
@media all and (max-width: 768px) {
  .top-brand-block .coin_price .coin {
    display: flex;
    flex-direction: column;
  }
  .top-brand-block .coin_price .coin .turn {
    margin-right: 0;
  }
}
.top-brand-block .buy_turn_coin_btn {
  margin-top: 75px;
  margin-bottom: 300px;
}
.top-brand-block .buy_turn_coin_btn button {
  width: 192px;
  height: 48px;
  border: 2px solid #D0AD6A;
  background-color: #D0AD6A;
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  border-radius: 0px;
}
.top-brand-block .buy_turn_coin_btn button:hover {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: 1px solid #D0AD6A;
}
.top-brand-block .adv {
  margin-top: 74px;
  font-size: 48px;
  text-align: center;
  letter-spacing: 8px;
  line-height: 1.2;
}
.top-brand-block .stats-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.top-brand-block .stats-container .stats {
  /*margin: 84px 0 42px;*/
  /*padding: 0 30px;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  max-width: 1200px;
  margin: 0;
  padding: 10px 0;
  letter-spacing: 2.5px;
  font-weight: 600;
}
.top-brand-block .stats-container .stats li + li {
  margin-left: 50px;
}
.top-brand-block .stats-container .separator {
  width: 1px;
  height: 20px;
  background-color: #D0AD6A;
}
.top-brand-block .stats-container .stats_item {
  text-align: center;
  font-size: 20px;
}
.top-brand-block .stats-container .stats_item span:first-child {
  color: #D0AD6A;
}
@media all and (max-width: 1440px) {
  .top-brand-block .top-brand-logo {
    margin-top: 150px;
    width: 175px;
  }
  .top-brand-block .slogan {
    margin-top: 25px;
    font-size: 37px;
  }
  .top-brand-block .slogan .medium {
    font-size: 30px;
  }
  .top-brand-block .adv {
    margin-top: 45px;
    font-size: 32px;
  }
}
@media all and (max-width: 1200px) {
  .top-brand-block .stats {
    width: 100%;
  }
  .top-brand-block .stats .stats_item {
    padding: 0;
  }
}
@media all and (max-width: 992px) {
  .top-brand-block .stats .stats_item:after {
    display: none;
  }
}
@media all and (max-width: 768px) {
  .top-brand-block .top-brand-logo {
    width: 22%;
  }
  .top-brand-block .adv {
    font-size: 25px;
  }
  .top-brand-block .stats {
    margin: 40px 20px 35px 20px;
    width: 100%;
    flex-wrap: wrap;
  }
  .top-brand-block .stats .separator {
    display: none;
  }
  .top-brand-block .stats .stats_item {
    width: 100%;
  }
}
@media all and (max-width: 575px) {
  .top-brand-block .top-brand-logo {
    width: 30%;
    margin-top: 150px;
  }
  .top-brand-block .slogan {
    font-size: 36px;
  }
  .top-brand-block .slogan .medium {
    font-size: 28px;
    color: #CA9C55;
  }
  .top-brand-block .adv {
    font-size: 28px;
    letter-spacing: 6px;
  }
}
@media all and (max-width: 400px) {
  .top-brand-block .top-brand-logo {
    width: 45%;
  }
}
@media (max-width: 575px) {
  .top-brand-block .top-brand-block .adv {
    font-size: 20px;
    letter-spacing: 2px;
  }
}
.coin_description_block {
  width: 100%;
  background-color: #ffffff;
}
.coin_description_block .card_area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-items: center;
  justify-content: center;
  padding: 65px 0;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
  z-index: 10;
  position: relative;
}
.coin_description_block .coin_description {
  background-color: #FCF9F4;
}
.coin_description_block .coin_description_block-timeline {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 83px;
}
.coin_description_block .coin_description_block-timeline img {
  width: 1200px;
}
.coin_description_block .container {
  padding: 96px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media all and (max-width: 1200px) {
  .coin_description_block .container {
    flex-direction: column;
  }
}
.coin_description_block .container .left_side_desc {
  padding-top: 50px;
}
.coin_description_block .container .left_side_desc .card_description_logo {
  margin-left: 51px;
  max-width: 260px;
}
.coin_description_block .container .right_side_desc {
  padding: 0px 0px 0px 72px;
  z-index: 999;
}
.coin_description_block .container .right_side_desc .card_description-under-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 24px;
  width: 70%;
  letter-spacing: 1px;
}
.coin_description_block .container .right_side_desc .title {
  line-height: 1em;
  font-size: 72px;
  color: #000000;
  margin-bottom: 15px;
  font-weight: 400;
}
.coin_description_block .container .right_side_desc .description {
  line-height: 24px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
}
.coin_description_block .container .right_side_desc button {
  margin-top: 20px;
  width: 192px;
  height: 48px;
  font-size: 20px;
  border: 2px solid #D0AD6A;
  color: #000000;
  font-weight: 600;
  background-color: #D0AD6A;
  border-radius: 0px;
}
.coin_description_block .container .right_side_desc button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
@media all and (max-width: 1440px) {
  .desc_item {
    border-right: 1px solid #D0AD6A;
  }
  .desc_item .title {
    font-size: 24px;
  }
  .desc_item .description {
    font-size: 18px;
  }
  .coin_description_block .card_description .right_side_desc p {
    font-size: 26px;
  }
  .coin_description_block .card_description .right_side_desc .title {
    font-size: 70px;
  }
  .coin_description_block .card_description .right_side_desc .description {
    margin: 0 auto;
    max-width: 800px;
    text-align: left;
    font-size: 21px;
  }
  .coin_description_block .card_description .right_side_desc button {
    width: 192px;
    height: 48px;
    font-size: 20px;
    border: 2px solid #D0AD6A;
    color: #000000;
    font-weight: 600;
  }
  .coin_description_block .card_description .right_side_desc button:hover {
    background: #000;
    color: #D0AD6A;
    border: 2px solid #D0AD6A;
  }
  .card_description_logo {
    width: 250px;
  }
}
@media all and (max-width: 1250px) {
  .coin_description_block .coin_description_block-timeline img {
    width: 90%;
  }
}
@media all and (max-width: 1200px) {
  .coin_description_block .container {
    padding: 0;
    flex-direction: column;
  }
  .coin_description_block .container .right_side_desc .card_description-under-title {
    width: 100%;
    font-size: 31px;
  }
  .coin_description_block .container .left_side_desc {
    padding-top: 65px;
    max-width: 260px;
    width: 100%;
    text-align: center;
  }
  .coin_description_block .container .left_side_desc .card_description_logo {
    margin-left: 0;
  }
  .coin_description_block .container .right_side_desc {
    width: 70%;
    padding: 96px 0 96px 0;
    text-align: center;
  }
  .coin_description_block .container .right_side_desc .title {
    font-size: 40px;
  }
  .coin_description_block .container .right_side_desc .description {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }
}
@media all and (max-width: 996px) {
  .desc_item {
    border-right: 0px solid transparent;
    margin: 0 10px;
  }
  .coin_description_block .coin_description_block-timeline {
    padding: 20px 0;
  }
}
@media all and (max-width: 768px) {
  .coin_description_block .container .left_side_desc {
    padding-top: 35px;
  }
  .coin_description_block .container .right_side_desc {
    padding: 50px 0 35px 0;
  }
  .coin_description_block .container .right_side_desc .card_description-under-title {
    font-size: 25px;
  }
  .coin_description_block .container .right_side_desc .description {
    font-size: 18px;
  }
  .coin_description_block .card_area {
    padding-bottom: 0;
    padding-top: 35px;
  }
  .coin_description_block .coin_description_block-timeline {
    display: none;
  }
  .coin_description_block .desc_item.revolution_card {
    min-height: 255px;
  }
}
@media all and (max-width: 450px) {
  .coin_description_block .card_description .right_side_desc .card_description-under-title {
    font-size: 18px;
  }
  .coin_description_block .card_description .right_side_desc .description {
    font-size: 16px;
  }
  .coin_description_block .card_description .right_side_desc .title {
    font-size: 30px;
  }
}
.sport_xchange_block {
  width: 100vw;
  background-image: url('../img/video-bg.jpg');
  background-size: cover;
  background-position: 50% 50%;
  background-size: auto auto;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sport_xchange_block .sport_image {
  margin-top: 95px;
  padding: 20px;
}
.sport_xchange_block .sport_xchange_block_title {
  height: 65px;
}
.sport_xchange_block .sport_tools {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.sport_xchange_block .sport_tools .right_margin {
  margin: 10px;
}
.sport_xchange_block .sport_video {
  margin-top: 50.7px;
  width: 815.71px;
  border-width: 24px 20px 24px 20px;
  border-color: #ffffff;
  border-style: solid;
}
.sport_xchange_block .sport_video .video_player {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.sport_xchange_block .sport_video .video_player iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.sport_xchange_block .sport_desc {
  margin-top: 63px;
  margin-bottom: 122px;
}
.sport_xchange_block .sport_desc .xchange_description .desc_title {
  font-size: 70px;
  line-height: 1em;
}
.sport_xchange_block .sport_desc .xchange_description .desc_content {
  font-size: 22px;
  text-align: left;
  width: 1063px;
}
.sport_xchange_block .sport_tools {
  margin-top: 25px;
  margin-bottom: 20px;
}
.sport_xchange_block .sport_tools img {
  height: 56px;
}
@media all and (max-width: 1440px) {
  .sport_xchange_block .sport_xchange_block_title {
    width: 650px;
  }
  .sport_xchange_block .sport_desc .xchange_description .desc_title {
    font-size: 50px;
  }
}
@media all and (max-width: 1200px) {
  .sport_xchange_block .sport_desc .xchange_description div.desc_content {
    width: 900px;
  }
}
@media all and (max-width: 990px) {
  .sport_xchange_block .sport_image {
    text-align: center;
    margin-top: 65px;
  }
  .sport_xchange_block .sport_image img {
    width: 60%;
    height: inherit;
  }
  .sport_xchange_block .sport_tools img {
    height: 40px;
  }
  .sport_xchange_block .sport_tools {
    flex-wrap: wrap;
  }
  .sport_xchange_block .sport_video {
    width: 90%;
  }
  .sport_xchange_block .xchange_description .desc_title {
    font-size: 30px;
  }
  .sport_xchange_block .sport_desc .xchange_description div.desc_content {
    font-size: 16px;
    width: 75%;
    margin: 0 auto;
    margin-top: 35px;
  }
  .sport_xchange_block .xchange_description .desc_title {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .sport_xchange_block .sport_video {
    border-width: 5px 5px 5px 5px;
  }
  .sport_xchange_block .sport_image {
    margin-top: 15px;
  }
  .sport_xchange_block .sport_tools {
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .sport_xchange_block .sport_desc {
    margin-bottom: 35px;
  }
}
@media all and (max-width: 480px) {
  .sport_xchange_block .sport_image img {
    width: 90%;
  }
}
.sport_xchange_block .sport_desc .xchange_description .desc_title {
  font-size: 65px;
}
@media (max-width: 768px) {
  .sport_xchange_block .sport_desc .xchange_description .desc_title {
    font-size: 45px;
  }
}
@media (max-width: 450px) {
  .sport_xchange_block .sport_desc .xchange_description .desc_title {
    font-size: 30px;
  }
}
.sport_xchange_block .sport_desc .xchange_description .desc_content {
  font-size: 18px;
  text-align: center;
}
.xchange_description {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.xchange_description .desc_title {
  line-height: 1.33;
  color: #D0AD6A;
  text-align: center;
}
.xchange_description .desc_content {
  width: 56%;
  margin-top: 35px;
  line-height: 1.38;
  color: #ffffff;
}
.xchange_description .desc_content a {
  font-weight: 500;
  color: #D0AD6A;
}
@media all and (max-width: 1400px) {
  .xchange_description .desc_content {
    width: 100%;
    text-align: center;
  }
}
@media all and (max-width: 575px) {
  .xchange_description .desc_title {
    font-size: 50px;
  }
}
.turncoin_holder {
  background-color: #FCF9F4;
}
.turncoin_holder .turncoin_holder-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.turncoin_holder .turncoin_holder-text-part {
  width: 70%;
  text-align: left;
}
.turncoin_holder .turncoin_holder-text-part a {
  color: #D0AD6A;
  font-weight: 700;
}
.turncoin_holder .turncoin_holder-text-part .turncoin_holder-under-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 24px;
  max-width: 350px;
  letter-spacing: 1px;
}
.turncoin_holder .turncoin_holder-img-part {
  margin-left: -70px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1199px) {
  .turncoin_holder .turncoin_holder-img-part {
    margin-left: 0px;
  }
}
.turncoin_holder .turncoin_holder-img-part .turncoin {
  width: 100%;
  max-width: 417px;
}
.turncoin_holder .turncoin_holder-img-part .turncoin img {
  width: 100%;
}
.turncoin_holder .turncoin_holder-img-part .buy-btn {
  max-width: 192px;
}
.turncoin_holder .desc_title {
  font-size: 65px;
  line-height: 1em;
}
.turncoin_holder .desc_title .bold {
  color: #D0AD6A;
}
.turncoin_holder .desc_content {
  text-align: left;
  font-size: 20px;
  padding-top: 20px;
}
.turncoin_holder .slogan {
  text-align: left;
  margin-top: 50px;
}
.turncoin_holder .slogan .medium {
  font-size: 65px;
  font-weight: 500;
}
.turncoin_holder .slogan .semibold {
  font-size: 48px;
  font-weight: 400;
}
.turncoin_holder .buy-btn button {
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  font-size: 20px;
  border-radius: 0px;
  border: 2px solid #D0AD6A;
  color: #000000;
  font-weight: 600;
}
.turncoin_holder .buy-btn button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
@media all and (max-width: 1440px) {
  .turncoin_holder .xchange_description .desc_title {
    font-size: 70px;
  }
  .turncoin_holder .xchange_description .desc_content {
    font-size: 22px;
    margin: 0 auto;
    max-width: 800px;
  }
}
@media all and (max-width: 1200px) {
  .turncoin_holder .turncoin_holder-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .turncoin_holder .turncoin_holder-text-part {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .turncoin_holder .turncoin_holder-text-part a {
    color: #D0AD6A;
    font-weight: 700;
  }
  .turncoin_holder .turncoin_holder-text-part .turncoin_holder-under-title {
    font-size: 31px;
  }
  .turncoin_holder .turncoin_holder-img-part {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
  }
  .turncoin_holder .turncoin_holder-img-part .turncoin {
    width: 100%;
  }
  .turncoin_holder .turncoin_holder-img-part .turncoin img {
    width: 100%;
  }
  .turncoin_holder .desc_title {
    font-size: 65px;
    line-height: 1em;
  }
  .turncoin_holder .desc_title .bold {
    color: #D0AD6A;
  }
  .turncoin_holder .desc_content {
    text-align: center;
    font-size: 20px;
    padding-top: 20px;
  }
  .turncoin_holder .slogan {
    text-align: center;
  }
}
@media all and (max-width: 990px) {
  .xchange_description .desc_title {
    font-size: 40px;
  }
  .xchange_description .desc_content {
    font-size: 18px;
  }
  .turncoin_holder .slogan .medium {
    font-size: 30px;
  }
  .turncoin_holder .slogan .semibold {
    font-size: 25px;
  }
}
@media only screen and (max-width: 812px) {
  .turncoin_holder .slogan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 1em;
  }
  .turncoin_holder .slogan span + span {
    margin-left: 7px;
  }
}
@media only screen and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .turncoin_holder .slogan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 1em;
  }
  .turncoin_holder .slogan span + span {
    margin-left: 7px;
  }
}
@media all and (max-width: 768px) {
  .turncoin_holder .turncoin_holder-text-part .turncoin_holder-under-title {
    font-size: 25px;
  }
  .turncoin_holder .desc_title {
    font-size: 50px;
  }
  .turncoin_holder .desc_content {
    font-size: 16px;
  }
  .main_block .turncoin_holder {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media all and (max-width: 450px) {
  .turncoin_holder .turncoin_holder-text-part .turncoin_holder-under-title {
    font-size: 20px;
  }
  .turncoin_holder .desc_title {
    font-size: 30px;
  }
  .turncoin_holder .desc_content {
    width: 100%;
    font-size: 16px;
  }
  .turncoin_holder .turncoin_holder-img-part {
    width: 80%;
  }
}
@media all and (max-width: 350px) {
  .turncoin_holder .turncoin_holder-text-part .turncoin_holder-under-title {
    font-size: 16px;
  }
  .turncoin_holder .slogan .medium {
    font-size: 20px;
  }
  .turncoin_holder .slogan .semibold {
    font-size: 16px;
  }
}
.purchase_block {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}
.purchase_block .header,
.purchase_block .footer {
  position: relative;
}
.purchase_block .footer {
  margin-top: 0px;
}
.purchase_block .purchase_area {
  position: relative;
  width: 100vw;
}
.purchase_block .purchase_area .coin_prices {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  font-weight: 400;
  min-height: 65px;
  padding: 20px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
}
.purchase_block .purchase_area .coin_prices span {
  display: inline-block;
}
.purchase_block .purchase_area .coin_prices span + span {
  margin-left: 50px;
}
@media all and (max-width: 1600px) {
  .purchase_block .purchase_area .coin_prices span + span {
    margin-left: 40px;
  }
}
@media all and (max-width: 1500px) {
  .purchase_block .purchase_area .coin_prices span + span {
    margin-left: 30px;
  }
}
@media all and (max-width: 1400px) {
  .purchase_block .purchase_area .coin_prices span + span {
    margin-left: 20px;
  }
}
@media all and (max-width: 1300px) {
  .purchase_block .purchase_area .coin_prices span {
    font-size: 16px;
  }
  .purchase_block .purchase_area .coin_prices span + span {
    margin-left: 20px;
  }
}
.purchase_block .purchase_area .coin_prices.mobile {
  display: none;
}
@media all and (max-width: 1150px) {
  .purchase_block .purchase_area .coin_prices {
    display: none;
  }
  .purchase_block .purchase_area .coin_prices.mobile {
    display: block;
  }
  .purchase_block .purchase_area .coin_prices.mobile .ant-carousel .slick-slide {
    text-align: center;
    height: 50px;
    margin-top: 0px;
    line-height: 1em;
    font-size: 16px;
    color: white;
    overflow: hidden;
  }
}
@media all and (max-width: 768px) {
  .purchase_block .purchase_area .coin_prices.mobile .ant-carousel .slick-slide {
    margin-top: 20px;
  }
}
@media screen and (max-width: 830px) {
  .purchase_block .purchase_area .coin_prices {
    flex-wrap: wrap;
    justify-content: center;
  }
  .purchase_block .purchase_area .coin_prices span {
    text-align: center;
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  .purchase_block .purchase_area .coin_prices span {
    width: 30%;
  }
}
@media screen and (max-width: 500px) {
  .purchase_block .purchase_area .coin_prices {
    padding: 0 25px;
    line-height: 45px;
  }
  .purchase_block .purchase_area .coin_prices span {
    font-size: 14px;
  }
}
@media screen and (max-width: 380px) {
  .purchase_block .purchase_area .coin_prices span {
    width: 50%;
  }
}
.purchase_header {
  background-color: white;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
}
.purchase_header-wrap {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  z-index: 999;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.purchase_header-wrap .logo {
  color: #d0ad6a;
  font-size: 40px;
  font-weight: 700;
  display: flex;
}
.purchase_header-wrap .logo p {
  color: #000;
  font-weight: 400;
}
.purchase_header-wrap .logo p span {
  font-size: 40px;
}
.purchase_header-wrap .title {
  font-size: 36px;
  font-weight: 600;
  transform: translateX(50px);
}
.purchase_header-wrap .menu_bar {
  cursor: pointer;
  left: 100%;
  top: 50%;
  transform: translate(-100%, -50%);
  position: absolute;
}
.purchase_header-wrap .turn_coin_logo {
  cursor: pointer;
}
.purchase_header-wrap .nav_items {
  text-align: right;
  padding: 15px;
  margin-right: 20px;
}
.purchase_header-wrap .nav_items a {
  cursor: pointer;
  font-size: 18px;
  color: #000000;
  margin-right: 20px;
  border: none;
  padding: 5px 0px;
}
.purchase_header-wrap .nav_items .user {
  color: black;
  font-size: 25px;
  font-weight: bold;
  border-left: 1px solid #000;
  padding-left: 10px;
}
.purchase_header-wrap .nav_items .selected {
  border-bottom: 2px solid #D0AD6A;
  font-weight: 600;
}
.purchase_header-wrap .nav_items button {
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  color: #000000;
  font-size: 20px;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
  font-weight: 600;
}
.purchase_header-wrap .nav_items button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.purchase_header-wrap .fixed_nav {
  text-align: right;
  padding: 15px;
}
.purchase_header-wrap .fixed_nav a {
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;
  margin-left: 20px;
}
.purchase_header-wrap .mobile_menu {
  position: absolute;
  width: 100vw;
  left: 50%;
  top: 120px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  height: 0px;
  transform: translate(-50%, 0);
}
.purchase_header-wrap .mobile_menu_wrapper {
  padding: 30px 10px;
}
.purchase_header-wrap .mobile_menu.show {
  visibility: visible;
  opacity: 1;
  height: 600px;
}
.purchase_header-wrap .mobile_menu i {
  position: absolute;
  font-size: 24px;
  color: #ffffff;
  right: 10px;
  top: 10px;
}
.purchase_header-wrap .mobile_menu ul {
  padding: 0;
  list-style: none;
}
.purchase_header-wrap .mobile_menu ul li {
  cursor: pointer;
  padding: 20px 0px;
}
.purchase_header-wrap .mobile_menu ul li a {
  font-size: 18px;
  color: #ffffff;
  padding: 5px 0px;
}
.purchase_header-wrap .mobile_menu .selected {
  border-bottom: 2px solid #D0AD6A;
}
@media all and (max-width: 1280px) {
  .purchase_header {
    padding: 0 30px;
  }
  .purchase_header .menu_bar {
    display: block;
  }
  .purchase_header .turn_coin_logo_wrapper {
    margin: 0 auto;
    text-align: center;
  }
  .purchase_header .turn_coin_logo_wrapper .turn_coin_logo {
    width: 100%;
    max-width: 304px;
  }
  .purchase_header .title {
    display: none;
  }
  .purchase_header .nav_items {
    display: none;
  }
  .purchase_header .fixed_nav {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  .purchase_header .turn_coin_logo_wrapper {
    margin: 0;
    width: 80%;
  }
  .purchase_header .logo {
    font-size: 45px;
  }
  .purchase_header .logo p span {
    font-size: 30px;
  }
}
.purchase_brand_block {
  background: url('../img/plane-bg.jpg');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 744px;
  text-align: center;
}
.purchase_brand_block img {
  margin-top: 96px;
  max-width: 405px;
  max-height: 408px;
  width: 100%;
}
.purchase_brand_block .turn_coin_title {
  font-size: 70px;
  color: #ffffff;
  margin-top: 7px;
}
.purchase_brand_block .turn_coin_title .turn {
  color: #D0AD6A;
}
@media all and (max-width: 992px) {
  .purchase_brand_block .turn_coin_title {
    font-size: 60px;
  }
}
@media all and (max-width: 768px) {
  .purchase_brand_block .turn_coin_title {
    font-size: 45px;
  }
  .purchase_brand_block img {
    margin-top: 140px;
    max-width: 350px;
  }
}
@media all and (max-width: 500px) {
  .purchase_brand_block img {
    margin-top: 100px;
  }
}
@media (max-width: 410px) {
  .purchase_brand_block img {
    margin-top: 100px;
    max-width: 300px;
  }
  .purchase_brand_block .turn_coin_title {
    font-size: 31px;
  }
  .purchase_brand_block {
    height: 560px;
  }
}
.turncoin_purchase_detail_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
.turncoin_purchase_detail_block strong {
  font-weight: 600;
}
.turncoin_purchase_detail_block .turncoin_purchase_title {
  margin-top: -48px;
  font-size: 4em;
  background-color: black;
  width: 672px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9;
}
@media screen and (max-width: 1280px) {
  .turncoin_purchase_detail_block .turncoin_purchase_title {
    width: 70%;
  }
}
.turncoin_purchase_detail_block .turncoin_purchase_description {
  font-size: 30px;
  color: black;
  padding: 90px 0;
  text-align: center;
  max-width: 1000px;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer {
  height: 192px;
  width: 1440px;
}
@media screen and (max-width: 1440px) {
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer {
    width: 100%;
  }
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
  width: 100%;
  height: 100%;
  font-size: 3em;
  color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 3px 3px #dbdbdb;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator strong {
  font-weight: 500;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 20px 30px;
  font-size: 1em;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item span {
  text-align: center;
  font-size: 1em;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item .calculator_item_value {
  font-weight: 600;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item input {
  width: 200px;
  border-style: solid;
  border-width: 1px;
  border-color: #F3EAD8;
  font-weight: 600;
}
.turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
  width: 100%;
  padding: 18px;
  text-align: left;
  color: red;
  font-size: 2em;
}
.turncoin_purchase_detail_block .review_btn_area {
  text-align: center;
  margin-bottom: 100px;
}
.turncoin_purchase_detail_block .review_btn_area button {
  width: 192px;
  height: 48px;
  font-size: 20px;
  background-color: #D0AD6A;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
  margin-top: 48px;
  color: #000000;
  font-weight: 600;
}
.turncoin_purchase_detail_block .review_btn_area button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.turncoin_purchase_detail_block .review_btn_area button:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.turncoin_purchase_detail_block .turncoin_purchase_info {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
}
.turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_title {
  font-size: 65px;
  color: black;
  margin-bottom: 31px;
}
.turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_description {
  margin-bottom: 40px;
}
.turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail strong {
  font-weight: bold;
}
.turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li > span + span {
  margin-left: 25px;
}
@media screen and (max-width: 1440px) {
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer {
    width: 90%;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
    font-size: 2.4em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    font-size: 1.7em;
  }
}
@media screen and (max-width: 1320px) {
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    font-size: 1.5em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
    display: flex;
    font-size: 2em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item {
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item > span:first-child {
    margin-right: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1110px) {
  .turncoin_purchase_detail_block .turncoin_purchase_title {
    width: 60%;
    height: 80px;
    font-size: 3.5em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_title {
    font-size: 50px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_description {
    font-size: 25px;
    padding: 50px 0;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer {
    height: 250px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
    font-size: 2em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item {
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  .turncoin_purchase_detail_block {
    font-size: 8px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_title {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_description {
    padding: 36px 0;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer {
    width: 90%;
    height: 320px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    padding: 8px;
    font-size: 1.8em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
    font-size: 2.7em;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item input {
    width: 100px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    padding: 8px;
  }
  .turncoin_purchase_detail_block .review_btn_area {
    text-align: center;
    margin-bottom: 40px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_description {
    width: 90%;
    text-align: center;
    margin-bottom: 20px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li span {
    display: block;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li > span:first-child {
    margin-right: 0;
    float: none;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li > span:last-child {
    float: none;
  }
}
@media screen and (max-width: 700px) {
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_title {
    font-size: 40px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li {
    font-size: 15px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item {
    width: 32%;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    padding: 8px;
  }
}
@media screen and (max-width: 660px) {
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_title {
    font-size: 30px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail {
    width: 100%;
    padding-left: 0;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_info .turncoin_purchase_info_detail li {
    font-size: 18px;
    margin-bottom: 20px;
    flex-direction: column;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item {
    width: 50%;
  }
}
@media screen and (max-width: 530px) {
  .turncoin_purchase_detail_block .turncoin_purchase_description {
    padding: 20px 10px;
    font-size: 20px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer {
    height: inherit;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    margin-left: 25px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator .calculator_item {
    width: 80%;
    flex-direction: row;
  }
}
@media screen and (max-width: 410px) {
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    font-size: 12px;
    margin-left: 15px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_title {
    font-size: 3em;
    text-align: center;
  }
}
@media screen and (max-width: 380px) {
  .turncoin_purchase_detail_block .review_btn_area button {
    margin-top: 40px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .notification {
    font-size: 12px;
  }
  .turncoin_purchase_detail_block .turncoin_purchase_calculator_cotainer .turncoin_purchase_calculator {
    font-size: 16px;
  }
}
.review-order-block {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}
.review-order-block .header,
.review-order-block .footer {
  position: relative;
}
.review-order-block .footer {
  margin-top: 0px;
}
.review-order-block .review-order-container {
  width: 1200px;
  margin: 0 auto;
}
.review-order-block .review-order-container .ant-col-xxl-offset-3 {
  margin-left: 0;
  width: 100%;
}
.review-order-block .ant-radio-inner {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
}
.review-order-block .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  background-color: #D0AD6A;
}
.review-order-block .review-order-content-wrapper {
  justify-content: space-between;
  width: 100%;
}
.review-order-block .turncoin-logo .turncoin-logo-title {
  display: block;
  font-size: 70px;
}
.review-order-block .turncoin-logo .turncoin-logo-title .coin {
  font-weight: 400;
}
.review-order-block .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
}
.review-order-block .ant-checkbox-inner::after {
  left: 30%;
  border-color: #D0AD6A;
}
.review-order-block .ant-checkbox-wrapper {
  color: #000000;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
}
.review-order-block .ant-checkbox-wrapper .ant-checkbox {
  margin-left: -35px;
}
.review-order-block .ant-checkbox-wrapper span {
  font-size: 18px;
  line-height: 18px;
  transform: translateY(4px);
}
.review-order-block .review-order-content {
  min-height: calc(100vh - 325px);
}
.review-order-block .review-order-content .left-side {
  display: flex;
  justify-content: flex-end;
}
.review-order-block .review-order-content .right-side {
  padding: 78px 0;
  font-size: 24px;
}
.review-order-block .review-order-content .right-side strong {
  font-weight: 700;
}
.review-order-block .review-order-content .right-side h1 {
  font-size: 48px;
  line-height: 72px;
  font-weight: 600;
}
.review-order-block .review-order-content .right-side .order-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
}
.review-order-block .review-order-content .right-side .order-list .order-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 30px;
  line-height: 72px;
}
.review-order-block .review-order-content .right-side .order-list .order-item .order-item-value {
  font-weight: 600;
}
.review-order-block .review-order-content .right-side .payment-method {
  font-size: 1em;
  margin-bottom: 60px;
}
.review-order-block .review-order-content .right-side .payment-method .title {
  line-height: 72px;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  text-decoration: none;
}
.review-order-block .review-order-content .right-side .agreement-list {
  max-width: 630px;
  width: 100%;
  margin-bottom: 50px;
}
.review-order-block .review-order-content .right-side .payment-order-btn {
  max-width: 630px;
  width: 100%;
  margin-bottom: 108px;
}
.review-order-block .review-order-content .right-side .payment-order-btn button {
  width: 192px;
  height: 48px;
  font-size: 20px;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
  color: #000000;
  font-weight: 600;
}
.review-order-block .review-order-content .right-side .payment-order-btn button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.review-order-block .review-order-content .right-side .payment-order-btn .order-btn {
  text-align: center;
  width: 192px;
  height: 48px;
  font-size: 20px;
  background-color: #D0AD6A;
}
.review-order-block .review-order-content .right-side .payment-order-btn .order-btn:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.review-order-block .review-order-content .right-side .payment-order-btn .back-btn {
  text-align: center;
  width: 192px;
  height: 48px;
  font-size: 20px;
  background-color: #ffffff;
  border-color: #D0AD6A;
  border-style: solid;
  border-width: 2px;
}
.review-order-block .review-order-content .right-side .downloads {
  max-width: 385px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.review-order-block .review-order-content .right-side .downloads .download-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.review-order-block .review-order-content .right-side .downloads .download-file img {
  width: 78px;
  height: 96px;
}
.review-order-block .review-order-content .right-side .downloads .download-file span {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-top: 5px;
}
.review-order-block .token-purchase-agreement {
  padding: 30px 30px;
}
.review-order-block .token-purchase-agreement .title {
  font-size: 16px;
  margin-bottom: 30px;
}
@media all and (max-width: 1280px) {
  .review-order-block .review-order-container {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 1180px) {
  .review-order-block .review-order-content .right-side {
    font-size: 22px;
    width: 60%;
  }
  .review-order-block .review-order-content .right-side .order-list .order-item {
    font-size: 25px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    line-height: 40px;
    font-size: 35px;
  }
  .review-order-block .review-order-content .right-side .payment-method .ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
    transform: scale(0.9);
  }
  .review-order-block .review-order-content .right-side .ant-radio {
    font-size: 15px;
  }
  .review-order-block .review-order-content .left-side {
    width: 35%;
  }
  .review-order-block .turncoin-logo {
    margin-right: 0;
  }
  .review-order-block .turncoin-logo .turncoin-logo-title {
    font-size: 60px;
  }
}
@media all and (max-width: 1080px) {
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-col {
    margin-right: 20px;
  }
}
@media all and (max-width: 992px) {
  .review-order-block .review-order-container {
    padding: 0 40px;
  }
  .review-order-block .review-order-content .right-side {
    font-size: 20px;
  }
  .review-order-block .review-order-content .right-side h1 {
    font-size: 40px;
  }
  .review-order-block .review-order-content .right-side .order-list {
    margin-bottom: 65px;
  }
  .review-order-block .review-order-content .right-side .order-list .order-item {
    font-size: 22px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn {
    margin-bottom: 80px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row .ant-col {
    margin-right: 35px;
  }
  .review-order-block .review-order-content .right-side .payment-method {
    margin-bottom: 20px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    line-height: 40px;
    font-size: 35px;
  }
  .review-order-block .turncoin-logo .turncoin-logo-title {
    font-size: 50px;
  }
}
@media all and (max-width: 880px) {
  .review-order-block .review-order-content .right-side {
    font-size: 18px;
    padding: 50px 0;
  }
  .review-order-block .review-order-content .right-side h1 {
    font-size: 28px;
  }
  .review-order-block .review-order-content .right-side .order-list {
    margin-bottom: 45px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row .ant-col {
    margin-right: 50px;
  }
  .review-order-block .review-order-content .right-side .payment-method {
    margin-bottom: 20px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    line-height: 40px;
    font-size: 35px;
  }
  .review-order-block .review-order-content .right-side .downloads {
    max-width: 330px;
  }
  .review-order-block .turncoin-logo .turncoin-logo-image {
    width: 300px;
  }
  .review-order-block .turncoin-logo .turncoin-logo-image .turncoin-logo-title {
    font-size: 45px;
  }
  .review-order-block .ant-checkbox-wrapper span {
    font-size: 16px;
  }
}
@media all and (max-width: 830px) {
  .review-order-block .purchase_header {
    width: 100vw;
  }
  .review-order-block .purchase_header .menu_bar {
    transform: translate(0%, -50%);
  }
  .review-order-block .review-order-container {
    padding: 0;
  }
  .review-order-block .review-order-content {
    width: 100vw;
  }
  .review-order-block .review-order-content .right-side {
    font-size: 22px;
    padding: 0;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 50px;
  }
  .review-order-block .review-order-content .right-side h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .review-order-block .review-order-content .right-side .order-list {
    margin-bottom: 45px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn {
    margin-bottom: 30px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row .ant-col {
    margin-right: 35px;
  }
  .review-order-block .review-order-content .right-side .payment-method {
    margin-bottom: 20px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    line-height: 40px;
    font-size: 35px;
  }
  .review-order-block .review-order-content .right-side .downloads {
    max-width: 355px;
  }
  .review-order-block .review-order-content .left-side {
    width: 100%;
    justify-content: center;
  }
  .review-order-block .turncoin-logo {
    width: 350px;
  }
  .review-order-block .turncoin-logo img {
    width: 100%;
    height: auto;
    max-width: initial;
  }
  .review-order-block .turncoin-logo .turncoin-logo-title {
    font-size: 45px;
  }
  .review-order-block .ant-checkbox-wrapper span {
    font-size: 16px;
  }
}
@media all and (max-width: 700px) {
  .review-order-block .review-order-content {
    width: 100vw;
  }
  .review-order-block .review-order-content .right-side {
    font-size: 18px;
  }
  .review-order-block .review-order-content .right-side .payment-method {
    margin-bottom: 20px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    font-size: 32px;
  }
}
@media all and (max-width: 580px) {
  .review-order-block .review-order-content .right-side {
    padding: 0 25px;
  }
  .review-order-block .review-order-content .right-side .order-list .order-item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 25px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .back-btn,
  .review-order-block .review-order-content .right-side .payment-order-btn .order-btn {
    width: 180px;
    height: 40px;
    font-size: 18px;
  }
  .review-order-block .review-order-content .right-side .payment-method {
    margin-bottom: 20px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    font-size: 32px;
  }
  .review-order-block .review-order-content .right-side .payment-method .ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
    transform: scale(0.9) translateX(-14px);
  }
}
@media all and (max-width: 500px) {
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row {
    flex-direction: column;
    justify-content: center;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row .ant-col {
    margin-bottom: 10px;
  }
  .review-order-block .review-order-content .right-side .downloads {
    max-width: 260px;
    margin: 0 auto;
  }
}
@media all and (max-width: 410px) {
  .review-order-block .review-order-content .right-side h1 {
    font-size: 27px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn .ant-row .ant-col {
    margin-right: 80px;
  }
  .review-order-block .review-order-content .right-side .payment-method .ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
    transform: scale(0.8) translateX(-30px);
  }
}
@media all and (max-width: 367px) {
  .review-order-block .review-order-content .right-side h1 {
    font-size: 24px;
    line-height: 35px;
  }
  .review-order-block .review-order-content .right-side .order-list {
    margin-bottom: 25px;
  }
  .review-order-block .review-order-content .right-side .order-list .order-item {
    font-size: 22px;
  }
  .review-order-block .review-order-content .right-side .payment-order-btn {
    padding-right: 40px;
  }
  .review-order-block .review-order-content .right-side .payment-method .title {
    margin-bottom: 0;
  }
}
.tooltip {
  white-space: normal;
}
.tooltip .tooltip-content s .tooltip-arrow {
  height: 10px;
}
.tooltip .tooltip-content .tooltip-inner {
  font-size: 16px;
  min-width: 30px;
  min-height: 32px;
  padding: 10px 8px;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #E0DCDE;
  color: black;
}
.pdf-reader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 96px;
  width: 100vw;
}
.pdf-reader-container .pdf-reader {
  max-width: 1176px;
  max-height: 1152px;
  overflow: scroll;
  border: 1px solid;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.pdf-reader-container .pdf-reader::-webkit-scrollbar {
  width: 0 !important;
}
@media all and (max-width: 1200px) {
  .pdf-reader-container {
    display: none;
  }
}
.purchase_success_block {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}
.purchase_success_block .xchange-list-title h3 {
  font-weight: 600;
}
.purchase_success_block .xchange-list-container .xchange-list-content .xchange-list-item {
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 32px;
}
.purchase_success_block .xchange-list-container .xchange-list-content .xchange-list-item > :first-child {
  min-width: 250px;
}
.purchase_success_block .xchange-list-container .xchange-list-content .xchange-list-item > :last-child {
  font-weight: 600;
}
.purchase_success_block .purchase_success_content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 325px);
}
.purchase_success_block .purchase_success_content .turncoin-logo .turncoin-logo-title {
  display: block;
  font-size: 70px;
}
.purchase_success_block .purchase_success_content .turncoin-logo .turncoin-logo-title .coin {
  font-weight: 400;
}
.purchase_success_block .purchase_success_content .left_side {
  display: flex;
  justify-content: flex-end;
}
.purchase_success_block .purchase_success_content .left_side .order-banner {
  background-image: url("../img/purchase/banner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 408px;
  padding: 86px 0 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}
.purchase_success_block .purchase_success_content .left_side .order-banner img {
  width: 176px;
  height: 178px;
}
.purchase_success_block .purchase_success_content .left_side .order-banner > span:nth-child(2) {
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
}
.purchase_success_block .purchase_success_content .left_side .order-banner > span:nth-child(3) {
  font-size: 25px;
  line-height: 25px;
}
.purchase_success_block .purchase_success_content .order-contact-wrapper {
  display: none;
  background-color: #F7F2E9;
  padding: 36px 0;
  font-size: 18px;
}
.purchase_success_block .purchase_success_content .order-contact-wrapper .order-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 24px;
}
.purchase_success_block .purchase_success_content .order-contact-wrapper .order-contact .order-contact-message {
  font-weight: 400;
}
.purchase_success_block .purchase_success_content .order-contact-wrapper .order-contact .order-contact-email {
  font-weight: 700;
}
.purchase_success_block .purchase_success_content .order-detail {
  padding: 92px 0px;
}
.purchase_success_block .purchase_success_content .order-detail .order-wrapper {
  margin-bottom: 38px;
}
.purchase_success_block .purchase_success_content .order-detail .payment-method-wrapper {
  margin-bottom: 36px;
}
@media all and (max-width: 1280px) {
  .purchase_success_block .purchase_success_content {
    width: 100%;
    padding: 0 30px;
  }
}
@media all and (max-width: 1180px) {
  .purchase_success_block .purchase_success_content {
    padding: 0 40px;
  }
  .purchase_success_block .purchase_success_content .xchange-list-container .xchange-list-content .xchange-list-item {
    font-size: 25px;
  }
  .purchase_success_block .right_side {
    width: 60%;
  }
  .purchase_success_block .left_side {
    width: 40%;
  }
}
@media all and (max-width: 991px) {
  .purchase_success_block .purchase_success_content {
    padding: 0;
  }
  .purchase_success_block .purchase_success_content .order-contact-wrapper {
    display: block;
  }
  .purchase_success_block .purchase_success_content .order-detail {
    padding: 35px 48px 35px 48px;
  }
  .purchase_success_block .purchase_success_content .order-detail > h3 {
    display: none;
  }
  .purchase_success_block .right_side {
    width: 100%;
  }
  .purchase_success_block .left_side {
    width: 100%;
  }
}
@media all and (max-width: 650px) {
  .purchase_success_block .purchase_success_content .xchange-list-container .xchange-list-content .xchange-list-item {
    font-size: 20px;
  }
}
@media all and (max-width: 520px) {
  .purchase_success_block .purchase_success_content .xchange-list-container .xchange-list-content .xchange-list-item {
    display: flex;
    flex-direction: column;
  }
  .purchase_success_block .purchase_success_content .xchange-list-container .xchange-list-content .xchange-list-item span {
    text-align: center;
  }
  .purchase_success_block .purchase_success_content .xchange-list-title {
    text-align: center;
  }
}
@media all and (max-width: 480px) {
  .purchase_success_block .purchase_success_content .order-contact-wrapper {
    padding: 24px 24px;
    font-size: 18px;
  }
  .purchase_success_block .purchase_success_content .order-contact-wrapper .order-contact .order-contact-company {
    display: flex;
    flex-direction: column;
  }
  .purchase_success_block .purchase_success_content .order-detail {
    padding: 32px 24px 48px 24px;
  }
}
.xchange-note {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}
.xchange-note i {
  color: #D0AD6A;
  font-size: 48px;
}
.xchange-note .note {
  padding: 0 0 0 24px;
  color: red;
  line-height: 24px;
}
@media all and (max-width: 768px) {
  .xchange-note {
    font-size: 14px;
  }
  .xchange-note i {
    font-size: 36px;
  }
  .xchange-note .note {
    color: #000000;
  }
}
@media all and (max-width: 320px) {
  .xchange-note .note {
    padding: 0 0 0 11px;
    line-height: 16px;
  }
}
.terms_block {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}
.terms_block .header,
.terms_block .footer {
  position: relative;
}
.terms_block .footer {
  margin-top: 0px;
}
.terms_block .terms_area {
  margin-top: 150px;
  min-height: calc(100vh - 325px);
}
.terms_block .terms_area .title {
  float: none;
  max-width: 580px;
  text-align: center;
  color: #000;
  margin: 40px auto;
  font-size: 50px;
  text-transform: uppercase;
  position: relative;
}
.terms_block .terms_area .wrap {
  float: none !important;
  max-width: 830px;
  margin: 0 auto;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding: 20px 20px;
  width: 100%;
  font-size: 0.9375rem;
}
.terms_block .terms_area .wrap p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.terms_block .terms_area .content {
  width: 100%;
}
.search-form {
  margin-bottom: 16px;
}
.search-form .search-button {
  font-size: 20px;
  border-radius: 25px;
}
.search-form .ant-form.ant-form-inline {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.search-form .ant-form-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  flex: 0 0 312px;
}
.search-form .ant-form-item button {
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  color: #000000;
  font-size: 20px;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
}
.search-form .ant-form-item button:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.search-form .ant-form-item input {
  width: 192px;
  height: 48px;
  border-radius: 25px;
  font-size: 20px;
}
@media all and (max-width: 360px) {
  .search-form .ant-form-item input {
    width: 160px;
  }
}
.backend-header .mobile_menu.show {
  top: 150px;
}
.backend-header .right-aligned a.active {
  border-bottom: 2px solid #d0ad6a;
  padding-bottom: 2px;
  font-weight: 700;
}
.ant-pagination .ant-table-pagination {
  display: none;
}
.ant-select-selection {
  background-color: transparent;
  border: none;
}
.ant-select-dropdown-menu-item {
  background: transparent;
}
.ant-select-dropdown-menu-item:hover {
  background: #D0AD6A;
}
.ant-select-disabled .ant-select-selection {
  background: transparent;
  cursor: unset;
}
.backend-container > .backend .backend-menu,
.backend-container > .backend .backend-content {
  box-sizing: border-box;
}
.backend-container > .backend .table-value-content {
  font-size: 18px;
}
.backend-container > .backend .backend-menu {
  background-color: #F7F2E9;
}
.backend-container > .backend .backend-menu ul {
  background-color: #F7F2E9;
  padding-top: 10px;
}
@media all and (max-width: 994px) {
  .backend-container > .backend .backend-menu ul {
    width: 48px;
  }
}
.backend-container > .backend .backend-menu ul.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 0px !important;
}
.backend-container > .backend .backend-menu ul.ant-menu-inline-collapsed > .ant-menu-item > .menu-item {
  padding-left: 12px;
}
.backend-container > .backend .backend-menu ul .menu-item {
  display: flex;
  align-items: center;
  border-width: 0 0 0 5px;
  border-style: solid;
  border-color: #F7F2E9;
  padding-left: 20px;
  height: 48px;
}
.backend-container > .backend .backend-menu ul .menu-item svg {
  fill: #D0AD6A !important;
}
.backend-container > .backend .backend-menu ul .menu-item:hover {
  background-color: #D0AD6A;
  border-color: #000000;
  color: #FFFFFF;
}
.backend-container > .backend .backend-menu ul .menu-item:hover svg {
  fill: #FFFFFF !important;
}
.backend-container > .backend .backend-menu ul .menu-item-selected {
  border-color: #000000;
  background-color: #D0AD6A;
  color: #FFFFFF;
}
.backend-container > .backend .backend-menu ul .menu-item-selected svg {
  fill: #FFFFFF !important;
}
.backend-container > .backend .backend-menu ul .ant-menu-item-selected {
  background-color: #D0AD6A;
}
.backend-container > .backend .backend-menu ul li {
  background-color: #F7F2E9;
  margin: 0px 0px 0px 0px;
  height: 56px;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
}
.backend-container > .backend .backend-menu .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 12px !important;
}
.backend-container > .backend .backend-menu a {
  color: black;
  font-size: 20px;
}
.backend-container > .backend .backend-menu a svg {
  margin-right: 10px;
}
.backend-container > .backend .backend-menu a + a {
  margin-top: 5px;
}
.backend-container > .backend .backend-content {
  min-width: 240px;
  flex: 1;
  padding: 35px 75px;
  justify-content: flex-start;
}
@media all and (max-width: 994px) {
  .backend-container > .backend .backend-content {
    padding: 20px 18px;
  }
}
.backend-header .logo {
  color: #d0ad6a;
  font-size: 55px;
  font-weight: 700;
  display: flex;
}
.backend-header .logo p {
  color: #fff;
  font-weight: 400;
}
.backend-header .logo p span {
  font-size: 40px;
}
.backend-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
.backend-container .backend {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.backend-container .backend-header,
.backend-container .backend-footer {
  position: unset;
}
.table-user-logins .table-user-login-content {
  padding: 15px!important;
  font-size: 18px;
}
.table-user-content.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters {
  padding: 15px;
  font-size: 18px;
}
@media (max-width: 650px) {
  .table-user-content.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters span {
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .backend-container > .backend .backend-content {
    padding: 30px 20px;
  }
}
@media (max-width: 1110px) {
  .backend-container > .backend .table-value-content {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .backend-container > .backend .table-value-content {
    font-size: 13px;
  }
}
.backend-header {
  padding: 25px 34px!important;
  min-height: 120px;
  height: unset;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #000;
  color: #FFFFFF;
  width: 100vw;
}
.backend-header .turn_coin_logo {
  height: 65px;
  width: auto;
}
.backend-header .mb-centered_logo {
  width: 50px;
}
.backend-header .centered,
.backend-header .mb-centered,
.backend-header .right-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
}
.backend-header .right-aligned a {
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  border: none;
  padding: 5px 0px;
}
.backend-header .right-aligned .selected {
  border-bottom: 2px solid #D0AD6A;
  font-weight: 600;
}
.backend-header .centered {
  flex-grow: 1;
}
.backend-header .centered .drivecoin {
  max-height: 65px;
  margin-right: 10px;
}
.backend-header .centered span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}
.backend-header .centered span .subcolor {
  color: #D9BA7E;
  font-weight: bold;
}
@media (max-width: 1600px) {
  .backend-header .centered {
    display: none;
  }
}
.backend-header .right-aligned a {
  margin-left: 20px;
}
.backend-header .right-aligned .user {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  border-left: 1px solid #fff;
  padding-left: 10px;
  text-transform: capitalize;
}
.backend-header .mb-centered {
  display: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
}
.backend-header .mb-centered .subcolor {
  color: #D9BA7E;
  font-weight: bold;
}
@media (max-width: 1600px) {
  .backend-header .centered {
    display: none;
  }
  .backend-header .mb-centered {
    display: flex;
  }
}
@media all and (max-width: 1048px) {
  .backend-header {
    padding: 15px 30px 15px 80px !important;
  }
  .backend-header .menu_bar {
    display: block;
    transform: translateY(-5px);
    top: 45px;
  }
  .backend-header .logo-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .backend-header .centered {
    max-height: 40px;
    font-size: 20px;
  }
  .backend-header .centered .drivecoin {
    display: none;
  }
  .backend-header .centered span {
    font-size: 20px;
  }
  .backend-header .right-aligned {
    display: none;
  }
}
.backend-footer {
  margin-top: 0;
}
@media all and (max-width: 994px) {
  .backend-footer {
    padding: 20px 0;
  }
}
@media (min-width: 995px) {
  .sm-desktop {
    display: none;
  }
  .lg-desktop {
    display: flex;
  }
}
@media all and (max-width: 994px) {
  .sm-desktop {
    display: block;
  }
  .lg-desktop {
    display: none;
  }
}
@media all and (max-width: 480px) {
  .backend-header .menu_bar {
    transform: translateY(-15px);
  }
  .backend-header .logo {
    font-size: 45px;
  }
  .backend-header .logo p span {
    font-size: 30px;
  }
}
@media all and (max-width: 350px) {
  .backend-header .menu_bar {
    transform: translateY(-20px);
  }
  .backend-header .logo {
    font-size: 35px;
    margin-left: 25px;
  }
  .backend-header .logo p span {
    font-size: 25px;
  }
}
.sm-desktop {
  position: relative;
  height: auto;
}
.sm-desktop .fixed_nav {
  padding-right: 0;
}
.sm-desktop .fixed_nav a {
  font-size: 16px;
  margin-left: 14px;
  color: black;
}
.sm-desktop .fixed_nav a img {
  width: 16px;
}
.sm-desktop .centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.sm-desktop .centered .drivecoin {
  max-height: 65px;
  margin-right: 10px;
}
.sm-desktop .centered span {
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.sm-desktop .centered span .subcolor {
  color: #D9BA7E;
  font-weight: bold;
}
.turncoin-btn {
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  color: #000000;
  font-size: 20px;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
}
.turncoin-btn:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.registration-code {
  font-size: 18px;
  color: #5aad5b;
  margin-bottom: 48px;
  font-weight: bold;
}
.registration-code > div {
  margin-bottom: 10px;
}
.pdf-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  height: 240px;
  padding: 20px 20px;
}
.pdf-card .pdf-icon {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: 24px;
}
.pdf-card .pdf-name {
  word-break: break-word;
  text-align: center;
  font-size: 18px;
  color: #000000;
}
.data-room-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 1199px) {
  .data-room-container {
    padding: 0 20px;
    width: 100%;
  }
}
.data-room {
  margin: 10px 0;
}
.data-room .data-room-desc {
  font-size: 16px;
  margin-bottom: 80px;
}
.data-room .data-room-desc span {
  font-weight: 700;
}
@media all and (max-width: 1199px) {
  .data-room .data-room-desc {
    margin-bottom: 20px;
  }
}
.data-room .documents-container .documents-container-title {
  font-size: 32px;
}
.data-room .documents-container .documents-box {
  border: 1px solid #000000;
  padding: 30px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  min-height: 300px;
}
.document-upload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 24px;
  max-width: 768px;
  justify-content: space-between;
}
.document-upload .file-picker {
  margin-top: 20px;
  width: 192px;
  height: 48px;
  background-color: #D0AD6A;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  border: 2px solid #D0AD6A;
  border-radius: 0px;
}
.document-upload .file-picker:hover {
  background: #000;
  color: #D0AD6A;
  border: 2px solid #D0AD6A;
}
.document-upload .document-name {
  margin-top: 20px;
  font-size: 24px;
}
.document-upload .document-name > input {
  font-size: 24px;
  border: 1px solid #000000;
  height: 48px;
}
.document-upload .upload-button {
  margin-top: 20px;
  width: 192px;
  height: 48px;
  font-size: 24px;
  border-color: #000000;
  border-radius: 30px;
  background-color: gray;
  color: black;
  border: none;
}
.ehter_wallet_btn {
  padding: 20px 30px;
  background-color: #D0AD6A;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.ehter_scan_btn {
  padding: 20px 30px;
  background-color: #D0AD6A;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}
.order-summary-section {
  margin-bottom: 36px;
}
.order-summary-section .tradable {
  background-color: rgba(94, 190, 31, 0.05) !important;
}
.order-summary-section .non-tradable {
  background-color: rgba(255, 0, 0, 0.05) !important;
}
.order-summary-section .bought {
  font-size: 15px;
  padding-left: 40px !important;
}
.order-summary-section .given {
  font-size: 15px;
  padding-left: 40px !important;
}
.order-summary-section .reserved {
  color: #e63523 !important;
}
.order-summary-section .issued {
  color: #5aad5b !important;
}
.order-summary-section .pending {
  color: #2c70ba !important;
}
.order-summary-section .ant-descriptions-row .ant-descriptions-item-label {
  color: black;
  font-weight: bold;
  padding: 10px 14px;
  line-height: 1em;
}
.order-summary-section .ant-descriptions-row .ant-descriptions-item-content {
  color: black;
  font-weight: bold;
  text-align: right;
  padding: 10px 14px;
  line-height: 1em;
}
